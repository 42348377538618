import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import axios from "axios"
import React, { useState } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { Loading } from "../Utils/Loading"
// import { GetProfile } from "../ReactQueries/GetProfile"
import { loginRequest } from "../Security/authConfig"
import LoadingModal from "../Modal/LoadingModal"
/**
 * Denne er Start komponenten for MinSide. Den lastes etter /minside/ adressen.
 * Og den danner slikt sett hovedgrunnlaget for MinSide
 * Den skal være minst mulig, og helst kalle på andre komponenter som dashboards osv.
 */

const queryClient = new QueryClient()

const HomeContent = () => {
  const [name, setName] = useState("Yo")
  // const getProfileQuery = GetProfile()

  // const query = () => {
  // //   setName(
  // //     getProfileQuery.data?.firstName + " " + getProfileQuery.data?.lastName
  // //   )
  // // }
  const something = "heihei"
  return (
    <div>
      <div>{something}</div>
      <div className="">
        <p className="text-sm text-black">{name}</p>
        <button className="rounded bg-blue-500 p-1"> Show Name</button>
      </div>

      <ReactQueryDevtools />
    </div>
  )
}

const Home = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <HomeContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}
export default Home
